<template>
  <v-theme-provider light>
    <base-section id="get-started-section" class="text-center" space="86">
      <base-section-heading title="Get Started" color="blue"> Select your state, county and profession below </base-section-heading>
      <v-row justify="center">
        <v-dialog v-model="displayCannotBeLoggedInDialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
          <v-card>
            <v-app-bar dark color="accent" height="70px">
              <div>
                <v-icon color="yellow"> mdi-alert</v-icon>
              </div>
              <span class="ml-4 mr-0 my-4">&nbsp;</span>
              <v-toolbar-title>Can't create a new account</v-toolbar-title>
              <v-spacer></v-spacer>
              <div>
                <v-btn class="black--text font-weight-bold" color="grey lighten-5" elevation="10" height="30" :ripple="true" min-width="96" @click="displayCannotBeLoggedInDialog = false"> Cancel </v-btn>
              </div>
            </v-app-bar>
            <v-card-title size="text-h1">You are logged in.</v-card-title>
            <v-card-text>Please log out before attempting to create a new account…</v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-responsive class="d-flex align-center mx-auto mb-4" height="100%" max-width="700" width="100%">
        <v-container fluid>
          <v-form ref="form" v-model="formIsValid" lazy-validation>
            <v-row>
              <v-col cols="8" md="12" class="align-center mx-auto mb-4">
                <ComponentStatesCounties @callUpdateStateCountyProps="updateStateCountyProps" />
              </v-col>
              <v-col cols="8" md="3" class="align-center mx-auto mb-4">
                <v-text-field v-model="zip" label="ZIP code" required :rules="zipRules" />
              </v-col>
              <v-col cols="8" md="3" class="align-center mx-auto mb-4">
                <v-select id="profession" ref="professionref" v-model="professionModel" name="profession" :items="professions" item-text="name" item-value="id" :rules="[(v) => !!v || 'Profession is required']" label="Profession" :disabled="displayProfessionUnavailableAlert" required @change="onGetProfession($event)" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn v-show="!displayProfessionUnavailableAlert" :disabled="!formIsValid" color="primary" class="mr-4 font-weight-bold v-btn v-btn--contained theme--light v-size--x-large _next" :loading="logingAnimation" @click="validate()">
                  {{ registerbuttonstring }}
                </v-btn>
              </v-col>
            </v-row>
            <v-alert v-show="displayProfessionUnavailableAlert" prominent dense type="error">
              <v-row align="center">
                <v-container fluid>
                  {{ displayProfessionUnavailableMessage }}
                  <v-spacer /><br /><v-btn align="center" light @click="dismissProfessionUnavailableAlert">OK</v-btn><v-spacer />
                </v-container>
                <br />
              </v-row>
            </v-alert>
          </v-form>
        </v-container>
      </v-responsive>
    </base-section>
  </v-theme-provider>
</template>

<script>
import { AlertTimeout } from '@/RegistrationStage.ts'
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX } from '@/environments/EnvX.ts'
import { ProfessionsData } from '@/resources/data/ProfessionsData.ts'
import { RemoteAccess } from '@/RemoteAccess'
import { StatesNamesData } from '@/resources/data/StatesNamesData.ts'

export default {
  components: {
    ComponentStatesCounties: () => import('./ComponentStatesCounties.vue')
  },
  name: 'SectionFeatures',
  data: () => ({
    states: StatesNamesData,
    professionModel: {},

    selectedStateId: '',
    selectedCountyId: '',
    selectedProfessionId: '',
    zip: '',

    registerbuttonstring: 'Create my account',
    displayProfessionUnavailableMessage: 'This profession has already been claimed in this network with this zip code',
    displayProfessionUnavailableAlert: false,
    displayCannotBeLoggedInDialog: false,

    disableCountyMenu: true,

    counties: [],

    professions: ProfessionsData,

    formIsValid: true,
    name: '',
    zipRules: [(v) => !!v || 'Zip is required', (v) => (!isNaN(parseFloat(v)) && v && v.length <= 5) || 'ZIP must be 5 digits'],
    showLoadingCountiesActivityIndicator: null,
    logingAnimation: false,
    countiesAnimation: false
  }),
  watch: {
    showLoadingCountiesActivityIndicator() {
      const l = this.showLoadingCountiesActivityIndicator
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), this.longTimeoutProp)

      this.showLoadingCountiesActivityIndicator = null
    }
  },

  computed: {
    longTimeoutProp() {
      return AlertTimeout.long
    }
  },

  methods: {
    checkAvailability() {
      const urlParams = new URLSearchParams(window.location.search)

      RemoteAccess.publicFunctionApp(
        '📕 getstarted.checkavailability', // log hint
        endpoints.professionAvailableEndpoint, // endpoint
        environmentVariables.PROFESSION_AVAILABLE_KEY, // code
        {
          // post body
          professioncode: `${this.selectedProfessionId}`,
          redeemid: urlParams.get('invitation'),
          zip: `${this.zip}`,
          countycode: `${this.selectedCountyId}`,
          state: `${this.selectedStateId}`
        },

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.displayCannotBeLoggedInDialog = false
          this.displayProfessionUnavailableAlert = false
          this.displayProfessionUnavailableMessage = null
          this.showLoadingCountiesActivityIndicator = null

          return true
        },

        // onSuccess
        (_data) => {
          this.$AuthService
            .idTokenAsync('getStarted.profession')
            .then(() => {
              this.displayCannotBeLoggedInDialog = true
            })
            .catch((error) => {
              if (AuthServiceConstants.LOGGED_OUT === error.message) {
                this.showLoadingCountiesActivityIndicator = 'logingAnimation'
                this.registerAction()
              } else {
                EnvX.warn('🧨', error)
                this.displayProfessionUnavailableMessage = `Cannot verify at the moment (${error})`
                this.displayProfessionUnavailableAlert = true
              }
            })
          this.logingAnimation = false
        },

        // All sort of errors
        (_error, description) => {
          this.displayProfessionUnavailableMessage = description
          this.registerbuttonstring = 'Try again'
          this.displayProfessionUnavailableAlert = true

          this.logingAnimation = false
        }
      )
    },

    validate() {
      if (this.$refs.form.validate()) {
        if (this.selectedCountyId !== '' && this.selectedProfessionId !== '' && this.selectedStateId !== '' && this.zip !== '') {
          this.checkAvailability()
        } else {
          if (this.selectedCountyId === '') {
            this.registerbuttonstring = 'Select a county'
            // this.$refs.countyref.$options.propsData.value = this.selectedCountyId
            // this.$refs.stateref.$options.propsData.value = this.selectedStateId
            // this.$refs.professionref.$options.propsData.value = this.selectedProfessionId
          }
        }
      }
    },

    dismissProfessionUnavailableAlert() {
      this.countiesAnimation = false
      this.displayProfessionUnavailableAlert = false
    },

    updateStateCountyProps(statecode, countycode) {
      this.selectedStateId = statecode
      this.selectedCountyId = countycode
    },

    onGetProfession(profValue) {
      this.selectedProfessionId = profValue
    },

    // Redirection **must** be done by Vue. AuthService is not a navigation service
    redirectToRouteHandler(redirectRoute) {
      EnvX.assert(redirectRoute != null, '⧄ redirectRoute')
      if (redirectRoute) {
        EnvX.log('Redirecting to route «' + redirectRoute + '»')
        this.$router.push({
          name: redirectRoute,
          params: {}
        })
      }
    },

    registerAction() {
      const urlParams = new URLSearchParams(window.location.search)
      const redeemid = urlParams.get('invitation')
      localStorage.setItem('com.proinsight.redeemid', redeemid)
      localStorage.setItem('com.proinsight.state', this.selectedStateId)
      localStorage.setItem('com.proinsight.county', this.selectedCountyId)
      localStorage.setItem('com.proinsight.profession', this.selectedProfessionId)
      localStorage.setItem('com.proinsight.zip', this.zip)

      // On minimal info submission
      // @see authConfig:registrationRequest
      const statusRoutes = {
        successRoute: 'editprofileroute',
        failureRoute: 'homeroute'
      }

      this.$AuthService.register(this.redirectToRouteHandler, statusRoutes)
    }
  }
}
</script>

<style scoped>
._next {
  position: relative;
  top: +14px;
  width: 100%;
}
</style>
